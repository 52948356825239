<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!--
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Valida tu cuenta de usuario 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            {{ email }} <small>(Pendiente de confirmar)</small>
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="validateForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="validate"
            >
              <!-- name -->
              <b-form-group
                label="name"
                label-for="register-name"
              >
                <template v-slot:label>Nombre <code>*</code></template>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="name"
                  />
                </validation-provider>
              </b-form-group>

              <!-- surname -->
              <b-form-group
                label="surname"
                label-for="register-surname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="surname"
                  vid="surname"
                >
                  <b-form-input
                    id="register-surname"
                    v-model="surname"
                    name="register-surname"
                    placeholder="surname"
                  />
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
              >
                <template v-slot:label>Password <code>*</code></template>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Password confirmation"
                label-for="reset-password_confirmation"
              >
                <template v-slot:label>Confirmar Password <code>*</code></template>
                <validation-provider
                  #default="{ errors }"
                  name="password_confirmation"
                  vid="password_confirmation"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password_confirmation"
                      v-model="password_confirmation"
                      :type="password2FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password_confirmation"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- legalId -->
              <b-form-group
                label="CIF"
                label-for="register-legalId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CIF"
                  vid="legalId"
                >
                  <b-form-input
                    id="register-legalId"
                    v-model="legal_id"
                    name="register-legalId"
                    placeholder="CIF"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- address1 -->
              <b-form-group
                label="Dirección"
                label-for="register-address1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dirección"
                  vid="address1"
                >
                  <b-form-input
                    id="register-address1"
                    v-model="address1"
                    name="register-address1"
                    placeholder="Dirección"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- adress2 -->
              <b-form-group
                label=""
                label-for="register-address2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="address2"
                  vid="address2"
                >
                  <b-form-input
                    id="register-address2"
                    v-model="address2"
                    name="register-address2"
                    placeholder="address2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- city -->
              <b-form-group
                label="Ciudad"
                label-for="register-city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ciudad"
                  vid="city"
                >
                  <b-form-input
                    id="register-city"
                    v-model="city"
                    name="register-city"
                    placeholder="Ciudad"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- postal code -->
              <b-form-group
                label="Código postal"
                label-for="register-postal_code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="postal_code"
                  vid="postal_code"
                >
                  <b-form-input
                    id="register-postal_code"
                    v-model="postal_code"
                    name="register-postal_code"
                    placeholder="postal_code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- telephone -->
              <b-form-group
                label="Teléfono"
                label-for="register-telephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="telephone"
                  vid="telephone"
                >
                  <b-form-input
                    id="register-telephone"
                    v-model="telephone"
                    name="register-telephone"
                    placeholder="telephone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- avatar -->
              <b-avatar
                  size="lg"
                  :src="avatar"
              />
              <b-form-group
                label="avatar"
                label-for="register-avatar"
              >
                <validation-provider
                  #default="{ errors }"
                  name="avatar"
                  vid="avatar"
                >
                  <b-form-file
                    id="register-avatar"
                    v-model="avatar"
                    :placeholder=" avatar ? avatar.name : 'Choose a file or drop it here' "
                    drop-placeholder="Drop file here"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="status"
                  rules="required"
                >
                  {{ $t('Acept') }}
                  <b-link>{{ $t('privacy policy & terms') }}</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                {{ $t('Active account') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('Already have an account?') }}</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('Sign in instead') }}</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormFile, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BAvatar,
} from 'bootstrap-vue'
import axios from '@axios'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormFile,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BAvatar,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      legal_id: '',
      name: '',
      surname: '',
      password: '',
      password_confirmation: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      telephone: '',
      status: '',
      avatar: null,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    uuid() {
      return this.$route.params.uuid
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    slug() {
      return store.state.auth.company.slug
    },
  },
  mounted() {
    this.getCustomer()
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async getCustomer() {
      await axios.get('/sanctum/csrf-cookie')
      await axios.get(`/api/v1/${this.slug}/user-confirm/${this.uuid}`)
          .then(async res => {
            this.legal_id = res.data.legal_id
            this.name = res.data.name
            this.surname = res.data.surname
            this.password = res.data.password
            this.address1 = res.data.address1
            this.address2 = res.data.address2
            this.city = res.data.city
            this.state = res.data.state
            this.postal_code = res.data.postal_code
            this.telephone = res.data.telephone
            this.status = res.data.status
            this.email = res.data.email
            this.avatar = res.data.avatar
          })
          .catch(error => {
            this.$refs.validateForm.setErrors(error.response.data.error)
          })
    },
    async validate() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          const formHeaders = { headers: { 'Content-Type': 'multipart/form-data' } }
          const formData = new FormData()
          formData.append('legal_id', this.legal_id)
          formData.append('name', this.name)
          formData.append('surname', this.surname)
          formData.append('password', this.password)
          formData.append('password_confirmation', this.password_confirmation)
          formData.append('address1', this.address1)
          formData.append('address2', this.address2)
          formData.append('city', this.city)
          formData.append('state', this.state)
          formData.append('postal_code', this.postal_code)
          formData.append('telephone', this.telephone)
          formData.append('avatar', this.avatar)
          formData.append('_method', 'patch')

          await axios.post(`/api/v1/${this.slug}/user-confirm/${this.uuid}`, formData, formHeaders)
              .then(async res => {
                this.$router.replace({ name: 'auth-login' })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Usuario activada`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `Ya puedes loguearse con su usuario en Evorgy!`,
                        },
                      })
                    })
              })
              .catch(error => {
                this.$refs.validateForm.setErrors(error.response.data.error)
              })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
