var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Valida tu cuenta de usuario 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.email)+" "),_c('small',[_vm._v("(Pendiente de confirmar)")])]),_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"name","label-for":"register-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Nombre "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false:null,"placeholder":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"surname","label-for":"register-surname"}},[_c('validation-provider',{attrs:{"name":"surname","vid":"surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-surname","name":"register-surname","placeholder":"surname"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"New Password","label-for":"reset-password-new"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Password "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password confirmation","label-for":"reset-password_confirmation"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Confirmar Password "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"password_confirmation","vid":"password_confirmation","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password_confirmation","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password_confirmation","placeholder":"············"},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"CIF","label-for":"register-legalId"}},[_c('validation-provider',{attrs:{"name":"CIF","vid":"legalId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-legalId","name":"register-legalId","placeholder":"CIF"},model:{value:(_vm.legal_id),callback:function ($$v) {_vm.legal_id=$$v},expression:"legal_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Dirección","label-for":"register-address1"}},[_c('validation-provider',{attrs:{"name":"Dirección","vid":"address1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address1","name":"register-address1","placeholder":"Dirección"},model:{value:(_vm.address1),callback:function ($$v) {_vm.address1=$$v},expression:"address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"","label-for":"register-address2"}},[_c('validation-provider',{attrs:{"name":"address2","vid":"address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address2","name":"register-address2","placeholder":"address2"},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Ciudad","label-for":"register-city"}},[_c('validation-provider',{attrs:{"name":"Ciudad","vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-city","name":"register-city","placeholder":"Ciudad"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Código postal","label-for":"register-postal_code"}},[_c('validation-provider',{attrs:{"name":"postal_code","vid":"postal_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-postal_code","name":"register-postal_code","placeholder":"postal_code"},model:{value:(_vm.postal_code),callback:function ($$v) {_vm.postal_code=$$v},expression:"postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"register-telephone"}},[_c('validation-provider',{attrs:{"name":"telephone","vid":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-telephone","name":"register-telephone","placeholder":"telephone"},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-avatar',{attrs:{"size":"lg","src":_vm.avatar}}),_c('b-form-group',{attrs:{"label":"avatar","label-for":"register-avatar"}},[_c('validation-provider',{attrs:{"name":"avatar","vid":"avatar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"register-avatar","placeholder":_vm.avatar ? _vm.avatar.name : 'Choose a file or drop it here',"drop-placeholder":"Drop file here"},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}})]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"status","rules":"required"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('Acept'))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t('privacy policy & terms')))])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('Active account'))+" ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('Already have an account?')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Sign in instead')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }